import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/layouts/text.tsx";
import { Link } from 'gatsby';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div className='sidebar-layout'>
      <div className='sidebar-layout__main' markdown='1'>
        <p>{`Have a tough problem you can't figure out? Try searching the `}<a parentName="p" {...{
            "href": "/docs"
          }}>{`documentation`}</a>{` first. If you can't find what you're looking for, try Googling around.`}</p>
        <p>{`If you still can't find an answer, post your question on the `}<a parentName="p" {...{
            "href": "http://stackoverflow.com/questions/tagged/fullcalendar"
          }}>{`StackOverflow `}<strong parentName="a">{`fullcalendar`}</strong>{` tag`}</a>{`. When entering a new question, make sure you tag it with `}<strong parentName="p">{`fullcalendar`}</strong>{`.`}</p>
        <p>
  {`You can improve your chances of getting an answer by 900% if you `}
  <Link to='/reduced-test-cases' mdxType="Link">post a reduced test case &raquo;</Link>
        </p>
        <h2>{`Reporting a Bug`}</h2>
        <p>
  {`Think you've found a bug? Please carefully follow the `}
  <Link to='/reporting-bugs' mdxType="Link">bug report instructions &raquo;</Link>
        </p>
        <h2>{`Requesting a Feature`}</h2>
        <p>
  {`Have an idea for a new feature? Please carefully follow the `}
  <Link to='/requesting-features' mdxType="Link">feature request instructions &raquo;</Link>
        </p>
        <h2>{`Browser Testing & Version Compatibility`}</h2>
        <p>{`The latest version of FullCalendar is compatible with Firefox, Chrome, Safari, and Edge.`}</p>
      </div>
      <div className='sidebar-layout__sidebar'>
        <div className='sidebar-card'>
  <h3>Premium Support</h3>
  <p>FullCalendar Premium comes with 1 year of email support.</p>
  <Link to='/pricing' className='button button--filled button--blue' mdxType="Link">
    Learn more &raquo;
  </Link>
        </div>
      </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      